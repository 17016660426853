<template>
    <div class="page">
        <dv-loading class="pageLoading" v-if="loading">Loading...</dv-loading>
        <div class="header">
            <img class="bj" ref="bj" src="../assets/image/header6.png">
            <div class="container">
                <div class="left">
                    <div :class="['icon_tq', 'tq-'+weather.wea_img]"></div>
                    <div class="des">
                        <div class="t">{{weather.wea}} {{weather.tem2}} ~ {{weather.tem1}}℃</div>
                    </div>
                </div>
                <div class="middle">
                    <div class="t" @click="$router.back()" style="cursor: pointer;">用户健康服务数据大屏</div>
                    <div class="t1">康艾达连锁大药房</div>
                </div>
                <div class="right">
                    <div class="des">
                        <div class="t">{{timeStr}}</div>
                        <!-- <div class="t1">{{timeStr1}}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="body">
            <div class="leftContainer">
                <div class="top">
                    <div class="cardContainer">
                        <div class="title">用户服药数据看板</div>
                        <dv-scroll-board v-if="config.data.length>0" :key="key" :config="config" class="dv-scroll-board" />
                    </div>
                </div>
                <div class="cardContainer" style="margin-top: .2rem;">
                    <div class="title">激活设备趋势图</div>
                    <div id="chart" ref="chart" style="margin-top:0.1rem"></div>
                </div>
            </div>
            <div class="middleContainer">
                <div class="chartContainer">
                    <div id="chart" ref="chart4"></div>
                </div>
                <div class="price">
                    <div class="t">今日营业额</div>
                    <div :key="key" class="itemContainer">
                        <DigitalTransform class="item" v-for="(item,index) in stat_01.sale_amount" :key="index" :value="item" :interval="500"></DigitalTransform>
                    </div>
                    <div class="unit">元</div>
                </div>
                <div class="cardList">
                    <div class="card">
                        <img src="../assets/image/page6_card1.png" alt="">
                        <div class="t">全国连锁店</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.store_count" :interval="500"></DigitalTransform>
                            <!-- <div>{{stat_01.store_count}}</div> -->
                            <sub>家</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#ECE156']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/page6_card2.png" alt="">
                        <div class="t">服务用户数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.service_count" :interval="500"></DigitalTransform>
                            <sub>个</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#1DC9B7']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/page6_card3.png" alt="">
                        <div class="t">日服务次数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.users_count" :interval="500"></DigitalTransform>
                            <sub>次</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#1E8FFF']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                    <div class="card">
                        <img src="../assets/image/page6_card4.png" alt="">
                        <div class="t">激活设备数</div>
                        <div class="v">
                            <DigitalTransform :key="key" :value="stat_01.devices_count" :interval="500"></DigitalTransform>
                            <sub>台</sub>
                        </div>
                        <dv-border-box-8 :dur="5" :color="['unset', '#FFB822']" :reverse="false" style="width:100%;height:100%;position: absolute;top:0;left:0"></dv-border-box-8>
                    </div>
                </div>
                <!-- <div class="mapContainer">
                </div> -->

            </div>
            <div class="rightContainer">
                <div class="cardContainer card1" style="padding-bottom:0">
                    <div class="title">主动服务</div>
                    <div style="width:100%;height:2rem">
                        <div id="chart" ref="chart2" style="margin-top:0.1rem"></div>
                    </div>
                    <div class="listContainer">
                        <div class="item">
                            <div class="t">主动询问</div>
                            <div class="block">
                                <div class="act" :style="{width:stat_05.type1.width}"></div>
                                <div class="v">{{stat_05.type1.todo}}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="t">主动答疑</div>
                            <div class="block">
                                <div class="act" :style="{width:stat_05.type2.width}"></div>
                                <div class="v">{{stat_05.type2.todo}}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="t">主动协助</div>
                            <div class="block">
                                <div class="act" :style="{width:stat_05.type3.width}"></div>
                                <div class="v">{{stat_05.type3.todo}}</div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="t">主动告警</div>
                            <div class="block">
                                <div class="act" :style="{width:stat_05.type4.width}"></div>
                                <div class="v">{{stat_05.type4.todo}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cardContainer card2">
                    <div class="title">用药依从性分析</div>
                    <div id="chart" ref="chart3"></div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import worldData from "./json/china.json";
    echarts.registerMap("china", worldData);
    import axios from '@/axios/index'
    import DigitalTransform from "vue-digital-transform";
    import * as moment from 'moment'
    import ut from '@/comm/ut'
    import * as math from 'mathjs'
    moment.locale("zh-cn")
    export default {
        components: {
            DigitalTransform
        },
        data() {
            return {
                mapLableShowTimer: null,
                weather: {},
                actShowLableIndex: 0,
                timeStr: '',
                timeStr1: '', //星期几
                key: new Date().getTime(),
                loading: true,
                visualMapMax: 0,
                stat_01: {
                    devices_count: "",
                    sale_amount: [],
                    service_count: "",
                    store_count: "",
                    users_count: ""
                },
                stat_02: [],
                stat_03: [],
                stat_04: [],
                stat_05: {
                    type1: {

                    },
                    type2: {

                    },
                    type3: {

                    },
                    type4: {

                    }
                },
                stat_06: [],
                chart: null,
                chart1: null,
                chart2: null,
                chart3: null,
                chart4: null,
                refreshTimer: null,
                config: {
                    headerBGC: "rgba(30, 143, 255, 0.2)",
                    oddRowBGC: "rgba(30, 143, 255, 0.1)", //偶数行背景色
                    evenRowBGC: "rgba(30, 143, 255, 0)", //	奇数行背景色	
                    rowNum: 9,
                    header: ['店名', '使用人数', '设备总数', '服药正常', '服药异常'],
                    data: [],
                    // oddRowBGC: "transparent",
                    // evenRowBGC: " transparent;",
                    // hoverPause: true,
                    // waitTime: 1000,
                    columnWidth: [110],
                    align: ["left", "center", "center", "center", "center"]
                },

            }
        },
        watch: {
            '$store.state.onresizeNumber': {
                handler() {
                    this.key = new Date().getTime()
                    if (this.chart) { //解决页面变化导致布局的问题
                        this.chart.clear()
                        this.setChart()
                        this.chart.resize();
                    }
                    if (this.chart1) {
                        this.chart1.clear()
                        this.setChart1()
                        this.chart1.resize();
                    }
                    if (this.chart2) {
                        this.chart2.clear()
                        this.setChart2()
                        this.chart2.resize();
                    }
                    if (this.chart3) {
                        this.chart3.clear()
                        this.setChart3()
                        this.chart3.resize();
                    }
                    if (this.chart4) {
                        // this.chart4.clear()
                        // this.setChart4()
                        this.chart4.resize();
                    }
                }
            }
        },
        async created() {
            this.getWeather()
            await this.getData()
            this.setTime()
            this.loading = false
        },
        methods: {
            async getWeather() {
                let response = await axios.request({
                    url: "/api/common/weather",
                    method: "POST",
                    data: {
                        city: "厦门"
                    }
                })
                this.weather = response.data
            },
            setConfigData() { //用户服药数据看板
                let configData = []
                for (let i = 0; i < this.stat_02.length; i++) {
                    const item = this.stat_02[i];
                    let errDiv = item.errTaking > 0 ? `<div class="warning"><div class="dian"></div><div>${item.errTaking}</div></div>` : item.errTaking
                    configData.push([item.storeName, item.userAmt, item.deviceAmt, item.normalTaking, errDiv])
                }
                this.config.data = configData
            },
            setTime() { //设置时间
                moment.locale("zh-cn")
                this.timeStr = `${moment().format('LTS')}　${ moment().format('LL')}`
                moment.locale("en")
                this.timeStr1 = `${ moment().format('LL')}`
                if (setIntervalTime) clearInterval(setIntervalTime)
                let setIntervalTime = setInterval(() => {
                    moment.locale("zh-cn")
                    this.timeStr = `${moment().format('LTS')}　${ moment().format('LL')}`
                    moment.locale("en")
                    this.timeStr1 = `${ moment().format('LL')}`
                }, 1000);
            },
            async getData() { //请求数据 设置数据
                let response = await axios.request({
                    url: "/api/dataworks.org/pharmacy",
                    method: "GET",
                })
                // "stat_01": "基础统计数据",
                // "stat_02": "用户服药数据看板",
                // "stat_03": "激活设备趋势图",
                // "stat_04": "地图数据",
                // "stat_05": "主动服务",
                // "stat_06": "用药依从性分析"
                let { stat_01 = {}, stat_02 = [], stat_03 = [], stat_04 = [], stat_05 = {}, stat_06 = [], refresh = 300 } = response.data.data
                if (this.refreshTimer) clearInterval(this.refreshTimer)
                this.refreshTimer = setInterval(this.getData, refresh * 1000);
                let visualMapMax = 0
                stat_04.forEach(item => {
                    item.value = parseFloat(item.value)
                    item.gps = [parseFloat(item.gps[0]), parseFloat(item.gps[1])]
                    if (item.value > visualMapMax) visualMapMax = item.value
                })
                this.visualMapMax = visualMapMax
                stat_01.sale_amount = stat_01.sale_amount ? stat_01.sale_amount.split("") : []
                this.stat_01 = stat_01
                this.stat_02 = stat_02
                this.stat_03 = stat_03
                this.stat_04 = stat_04
                stat_05.type1.width = math.chain(math.bignumber(stat_05.type1.todo) / math.bignumber(stat_05.type1.done)).multiply(100) + "%"
                stat_05.type2.width = math.chain(math.bignumber(stat_05.type2.todo) / math.bignumber(stat_05.type2.done)).multiply(100) + "%"
                stat_05.type3.width = math.chain(math.bignumber(stat_05.type3.todo) / math.bignumber(stat_05.type3.done)).multiply(100) + "%"
                stat_05.type4.width = math.chain(math.bignumber(stat_05.type4.todo) / math.bignumber(stat_05.type4.done)).multiply(100) + "%"
                this.stat_05 = stat_05
                this.stat_06 = stat_06
                this.setChart()
                this.setChart1()
                this.setChart2()
                this.setChart3()
                this.setConfigData()
                // this.setConfigData1()
            },
            setChart() { //激活设备趋势图
                this.chart = echarts.init(this.$refs.chart);
                let UserAmtData = [] // 用户数
                let actvUserAmtData = [] // 活跃用户数
                let rgstDeviceAmtData = [] // 激活设备数 
                let dateData = []
                for (let i = 0; i < this.stat_03.length; i++) {
                    const item = this.stat_03[i];
                    UserAmtData.push(item.UserAmt)
                    actvUserAmtData.push(item.actvUserAmt)
                    rgstDeviceAmtData.push(item.rgstDeviceAmt)
                    dateData.push(`${item.month}月\n${item.year}`)
                }
                let series = [{
                        name: '用户数',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: UserAmtData
                    },
                    {
                        smooth: true,
                        name: '活跃用户数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: actvUserAmtData
                    },
                    {
                        smooth: true,
                        name: '激活设备数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: rgstDeviceAmtData
                }]
                this.chart.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(29, 201, 183, 1)", 'rgba(255, 184, 34, 1)', "rgba(30, 143, 255, 1)"],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },
                    legend: {
                        textStyle: {
                            fontSize: ut.fontSize(13),
                            color: 'rgba(255, 255, 255, 0.9)'
                        },
                        itemGap: ut.fontSize(5),
                        padding: [20, 0, 0, 0],
                        data: ['用户数', '活跃用户数', '激活设备数']
                    },
                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: dateData,
                        axisLabel: {
                            color: "rgba(255, 255, 255, 0.6)",
                            fontSize: ut.fontSize(11),
                        },

                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        }
                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })

            },
            setChart1() { //地图数据
                let rldData = [] // 热力点数据
                let stat_04 = this.stat_04
                let endData = []
                stat_04.forEach((item, index) => {
                    rldData.push({
                        name: item.city,
                        value: [item.gps[0], item.gps[1], item.value]
                    })
                    endData.push({
                        label: {
                            show: index === 0 ? true : false
                        },
                        name: item.city,
                        value: [item.gps[0], item.gps[1], item.value]
                    })
                })

                let series = [
                    { //热力点
                        zlevel: 999,
                        type: 'heatmap',
                        coordinateSystem: 'geo',
                        data: rldData,
                    },
                    {
                        zlevel: 99,
                        type: 'scatter', //层级比地图区高 用来触摸显示自定义tooltip
                        coordinateSystem: 'geo', //
                        symbolSize: 50,
                        itemStyle: {
                            opacity: 0,
                        },
                        data: rldData,
                        tooltip: {
                            borderColor: "",
                            borderWidth: 0,
                            backgroundColor: "",
                            padding: 0,
                            formatter: function(params, ticket, callback) {
                                if (params.seriesType == "scatter") {
                                    return `<div style="border: 1px solid #C216F4;height:32px;text-align: center;display:flex;font-size: 16px;color:#FFFFFF;font-weight: 600;width:auto">
                                            <div style="background: rgba(194, 22, 244, 0.4);line-height: 32px;padding:0 17px;border-right: 1px solid #C216F4;">${params.name}</div>
                                            <div style="line-height: 32px;padding:0 17px;background: rgba(194, 22, 244, 0.3);">${params.value[params.value.length-1]}</div>
                                        </div>`
                                }
                            }
                        }

                },
                    { // 结束点
                        visualMap: false,
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        zlevel: 1,
                        rippleEffect: {
                            brushType: 'stroke'
                        },
                        symbolSize: 6,
                        itemStyle: {
                            normal: {
                                opacity: 1,
                                color: 'rgba(255, 242, 77, 1)'
                            }
                        },
                        data: endData,
                        label: {
                            show: true,
                            color: "#FFFFFF",
                            opacity: 1,
                            position: "right",
                            offset: [0, 0],
                            formatter: function(params) {
                                return `{line|——}{name|${params.data.name}}{value|${params.data.value[params.data.value.length-1]}}`
                                // return `{name|${params.name}{value|{d}%}`,
                            },
                            rich: {
                                line: {
                                    color: "#FFFFFF"
                                },
                                name: {
                                    backgroundColor: 'rgba(194, 22, 244, 0.6)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                },
                                value: {
                                    backgroundColor: 'rgba(194, 22, 244,0.3)',
                                    lineHeight: 32,
                                    padding: [6, 17, 5, 17],
                                    borderColor: "#C216F4",
                                    borderWidth: 0.5,
                                    fontSize: 16,
                                    color: "#FFFFFF"
                                }
                            }
                        }
                }
            ];

                this.chart4 = echarts.init(this.$refs.chart4);
                this.chart4.setOption({
                    visualMap: {
                        show: false,
                        orient: "horizontal",
                        zlevel: 999,
                        type: "continuous", //类型为分段型
                        seriesIndex: 0, //指定取哪个系列的数据，即哪个系列的  默认取所有系列 不指定会覆盖所有颜色
                        min: 0,
                        max: this.visualMapMax,
                        splitNumber: 5, //分5段
                        inRange: {
                            color: ['rgba(255, 242, 77, 1)', 'rgba(255, 242, 77, 0.9)', 'rgba(255, 242, 77, 0.8)', 'rgba(255, 242, 77, 0.7)', 'rgba(255, 242, 77, 0.6)'] //每段的颜色
                        }
                    },
                    tooltip: {
                        show: true
                    },
                    grid: {
                        width: '100%',
                        height: '100%',
                        // left: '0%',
                        // right: '0%',
                        // bottom: '0%',
                        // top:'10%',
                        containLabel: true
                    },
                    // layoutCenter: ['70%', '50%'], //位置
                    // layoutSize: '65%', //大小
                    geo: {
                        top: '20%',
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params, ticket, callback) { return }
                        },
                        name: '世界地图',
                        type: 'map',
                        map: 'china',
                        label: {
                            emphasis: {
                                show: true,
                                color: '#fff'
                            }
                        },
                        zoom: 1.3,
                        // roam: true, //支持拖拽缩放
                        // scaleLimit: { //滚轮缩放的极限控制
                        //     min: 0.5, //缩放最小大小
                        //     max: 6, //缩放最大大小
                        // },
                        itemStyle: {
                            normal: {
                                borderWidth: 1, //边际线大小
                                // borderColor: 'red', //边界线颜色
                                areaColor: 'rgba(32, 51, 92, 1)', //默认区域颜色
                                borderColor: '#3D5C9F'
                            },
                            emphasis: {
                                show: true,
                                // areaColor: '#2a333d'
                                areaColor: '#3066ba', //鼠标滑过区域颜色
                                label: {
                                    show: true,
                                    textStyle: {
                                        color: '#fff'
                                    }
                                }
                            }
                        }
                    },
                    series
                })
                let options = this.chart4.getOption();
                if (this.mapLableShowTimer) clearInterval(this.mapLableShowTimer)
                this.mapLableShowTimer = setInterval(() => {
                    this.actShowLableIndex = this.actShowLableIndex === options.series[2].data.length - 1 ? 0 : this.actShowLableIndex + 1
                    options.series[2].data.forEach((item, index) => {
                        item.label.show = index === this.actShowLableIndex ? true : false
                    })
                    this.chart4.setOption(options, false);
                }, 3000);
            },

            setChart2() { //主动服务
                let stat_05 = this.stat_05
                let data = [
                    {
                        value: stat_05.service.done,
                        name: "已处理"
                    },
                    {
                        value: stat_05.service.done,
                        name: "待处理"
                    }
                ]

                this.chart2 = echarts.init(this.$refs.chart2);
                this.chart2.setOption({
                    tooltip: {
                        trigger: 'item'
                    },
                    grid: {
                        width: '100%',
                        height: '100%',
                        left: '0%',
                        top: '0%',
                        containLabel: true
                    },
                    legend: {
                        // padding: [5, 0, 0, 0],
                        // icon: "circle",
                        type: 'scroll',
                        pageIconColor: "rgba(255,255,255,1)",
                        pageIconInactiveColor: "rgba(255,255,255,0.5)",
                        pageTextStyle: {
                            color: "rgba(255,255,255,1)",
                            fontSize: ut.fontSize(14),
                        },
                        textStyle: {
                            fontSize: ut.fontSize(12),
                            color: "#FFFFFF"
                        }
                    },
                    color: ["#1E8FFF", '#FFB822'],
                    series: [
                        {
                            name: '主动服务',
                            type: 'pie',
                            animation: true,
                            radius: ['30%', '80%'],
                            top: "10%",
                            avoidLabelOverlap: true,
                            data,
                            label: {
                                show: true,
                                fontSize: ut.fontSize(14),
                                color: 'rgba(159, 189, 232, 1)',
                                formatter: params => {
                                    return `${params.data.value}\n${params.data.name}`
                                }
                            },
                        }
                    ]
                })

            },

            setChart3() { //用药依从性分析
                this.chart3 = echarts.init(this.$refs.chart3);
                let rateData = [] // 用户数
                let abnmlTakingData = [] // 活跃用户数
                let onTimeTakingData = [] // 激活设备数 
                let dateData = []
                for (let i = 0; i < this.stat_06.length; i++) {
                    const item = this.stat_06[i];
                    rateData.push(item.rate)
                    abnmlTakingData.push(item.abnmlTaking)
                    onTimeTakingData.push(item.onTimeTaking)
                    dateData.push(`${item.month}月\n${item.year}`)
                }
                let series = [{
                        name: '用药依从性（百分比)',
                        type: 'line',
                        smooth: true,
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: rateData
                    },
                    {
                        smooth: true,
                        name: '按时服药总次数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: abnmlTakingData
                    },
                    {
                        smooth: true,
                        name: '漏服药总次数',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        symbol: "circle",
                        symbolSize: ut.fontSize(8),
                        lineStyle: {
                            width: ut.fontSize(3)
                        },
                        data: onTimeTakingData
                }]
                this.chart3.setOption({
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    color: ["rgba(29, 201, 183, 1)", 'rgba(255, 184, 34, 1)', "rgba(30, 143, 255, 1)"],
                    grid: {
                        left: '0',
                        right: '0',
                        bottom: '0',
                        containLabel: true
                    },
                    legend: {
                        textStyle: {
                            fontSize: ut.fontSize(13),
                            color: 'rgba(255, 255, 255, 0.9)'
                        },
                        itemGap: ut.fontSize(5),
                        padding: [20, 0, 0, 0],
                        data: ['用药依从性（百分比)', '按时服药总次数', '漏服药总次数']
                    },
                    xAxis: [{
                        type: 'category',
                        axisTick: { show: false },
                        data: dateData,
                        axisLabel: {
                            color: "rgba(255, 255, 255, 0.6)",
                            fontSize: ut.fontSize(11),
                        },

                        axisLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.2)"
                            }
                        }
                    }],
                    yAxis: [{
                        axisLabel: {
                            show: false,
                        },
                        splitLine: {
                            lineStyle: {
                                color: "rgba(255, 255, 255, 0.08)"
                            }
                        },
                        type: 'value'
                    }],
                    series
                })
            }
        },
        destroyed() {
            if (this.mapLableShowTimer) clearInterval(this.mapLableShowTimer)
            if (this.refreshTimer) clearInterval(this.refreshTimer)
            if (this.chart) this.chart.dispose()
            if (this.chart1) this.chart1.dispose()
            if (this.chart2) this.chart2.dispose()
            if (this.chart3) this.chart3.dispose()
        }
    }
</script>
<style lang="less" scoped>
    .pageLoading {
        background-color: #1330b8;
        color: #FFFFFF;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 99;
        top: 0;
        left: 0;
    }

    .page {
        background-color: rgba(13, 27, 57, 1);
        display: flex;
        flex-direction: column;
        position: relative;
    }

    #chart {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }

    .header {
        width: 100%;
        position: relative;


        @keyframes light {
            from {
                opacity: 1;
            }

            to {
                opacity: 0.7;
            }
        }

        .bj {
            width: 100%;
            vertical-align: top;
            animation-name: light;
            animation-duration: 1s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            animation-direction: alternate;

        }

        .container {
            // border: 1px solid red;
            box-sizing: border-box;
            top: 0;
            left: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;

            .left {
                padding-left: 0.4rem;
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: left;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;

                .icon_tq {
                    font-size: .52rem;
                    margin-right: .2rem;
                }

                .des {


                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .23rem;
                        font-size: .16rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }


            }

            .middle {
                flex: auto;
                // border: 1px solid red;
                box-sizing: border-box;
                position: relative;

                .t {
                    position: absolute;
                    left: 2rem;
                    top: .41rem;
                    font-size: .5rem;
                    font-weight: 800;
                    color: rgba(255, 184, 34, 1);
                }

                .t1 {
                    position: absolute;
                    left: 3.7rem;
                    top: 0;
                    font-weight: 800;
                    color: rgba(255, 184, 34, 1);
                    font-size: .22rem;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }

            .right {
                width: 5.15rem;
                height: 100%;
                // border: 1px solid red;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: #FFFFFF;
                padding-top: .3rem;
                padding-right: 0.4rem;

                .des {
                    width: 2.88rem;
                    text-align: right;

                    .t {
                        font-size: .24rem;
                        font-weight: 400;
                        height: .34rem;
                    }

                    .t1 {
                        height: .23rem;
                        font-size: .16rem;
                        font-weight: 400;
                        opacity: 0.7;
                    }

                }



            }


        }
    }

    .body {
        flex: auto;
        position: relative;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        padding: .05rem .4rem .4rem .4rem;
        justify-content: space-between;
        width: 100%;
        // border: 1px solid red;

        /deep/ .dv-scroll-board .header .header-item {
            font-size: .14rem;
            font-weight: 800;
        }

        /deep/ .dv-scroll-board .rows .row-item {
            font-weight: 400;
            font-size: .14rem;
        }

        .leftContainer {
            flex-shrink: 0;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            flex-direction: column;
            // border: 1px solid red;
            width: 4.45rem;
            justify-content: space-between;

            .top {
                display: flex;
                flex-direction: column;
                position: relative;
                height: 4.82rem;
                flex-shrink: 0;
                background-color: rgba(29, 201, 183, 0.03);
                // border: 1px solid red;
                box-sizing: border-box;


                .cardContainer {
                    padding-left: 0.1rem;
                    padding-right: 0.1rem;
                }

                .dv-scroll-board {
                    margin-top: .2rem;

                    /deep/ .warning {
                        // border: 1px solid red;
                        box-sizing: border-box;
                        color: #F56C6C;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .dian {
                            background-color: #F56C6C;
                            border-radius: 50%;
                            width: 9px;
                            height: 9px;
                            margin-right: 6px;
                        }
                    }
                }

            }

            .cardContainer {
                flex: auto;
                background-color: rgba(29, 201, 183, 0.03);
            }

        }


        .cardContainer {
            width: 100%;
            height: 100%;
            overflow: hidden;
            padding: .2rem;
            box-sizing: border-box;
            // background: rgba(17, 44, 171, 0.8);
            display: flex;
            flex-direction: column;
            position: relative;

            .title {
                padding-left: .12rem;
                font-size: .28rem;
                font-weight: 800;
                color: #FFFFFF;
                position: relative;

                &::after {
                    content: "";
                    width: .04rem;
                    height: 70%;
                    left: 0;
                    top: 20%;
                    background: #1DC9B7;
                    position: absolute;
                }
            }



        }

        .middleContainer {
            // border: 1px solid yellow;
            box-sizing: border-box;
            margin: 0 .2rem;
            flex: auto;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;

            .price {
                margin: 0 auto;
                margin-top: .3rem;
                display: flex;
                align-items: flex-start;
                color: #FFFFFF;
                position: relative;
                z-index: 1;

                .t {
                    font-size: .16rem;
                    font-weight: 400;
                }

                .unit {
                    font-size: .16rem;
                    font-weight: 400;
                    margin-left: .12rem;
                }

                .itemContainer {
                    display: flex;
                    align-items: center;
                }

                .item {
                    width: .55rem;
                    height: .7rem;
                    background: #1E8FFF;
                    box-shadow: 0px .05rem .1rem .01rem rgba(0, 0, 0, 0.2);
                    border-radius: .04rem;
                    font-size: .5rem;
                    font-weight: 600;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: .12rem;

                }
            }


            .chartContainer {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }

            .cardList {
                flex-shrink: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;

                .card {
                    width: 2.13rem;
                    position: relative;
                    height: 1.4rem;

                    img {
                        vertical-align: top;
                        width: 100%;
                    }

                    .t {
                        position: absolute;
                        width: 100%;
                        text-align: center;
                        color: #FFFFFF;
                        font-size: .2rem;
                        font-weight: 400;
                        bottom: .15rem;
                    }

                    .v {
                        position: absolute;
                        width: 100%;
                        top: .2rem;
                        text-align: center;
                        font-size: .4rem;
                        font-weight: 800;
                        color: #ECE156;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        sub {
                            font-size: .2rem;
                            font-weight: 600;
                            margin-left: .2rem;
                            position: relative;
                            opacity: 0.8;
                            top: .05rem;
                        }
                    }

                    &:nth-child(2) {
                        .v {
                            color: #1DC9B7;
                        }

                    }

                    &:nth-child(3) {
                        .v {
                            color: #1E8FFF;
                        }
                    }

                    &:nth-child(4) {
                        .v {
                            color: #FFB822;
                        }
                    }
                }
            }

            .mapContainer {
                // border: 1px solid rgb(0, 255, 42);
                box-sizing: border-box;
                flex: auto;
                width: 100%;
            }

            .content {
                flex-shrink: 0;
                // border: 1px solid red;
                box-sizing: border-box;
                width: 100%;
                height: 2.43rem;

                .cardContainer {
                    padding-bottom: .14rem;

                    .dv-scroll-board {
                        margin-top: .2rem;
                        width: 100%;
                        flex: auto;
                        // border: 1px solid yellow;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .rightContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 4.45rem;
            height: 100%;

            // border: 1px solid red;
            box-sizing: border-box;

            .cardContainer {
                margin-top: .2rem;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .card1 {
                // border: 1px solid red;
                box-sizing: border-box;

                #chart {
                    flex: auto;
                    // border: 1px solid red;
                    box-sizing: border-box;
                }
            }

            .card1 {
                // border: 1px solid red;
                box-sizing: border-box;
                background-color: rgba(29, 201, 183, 0.03);
                padding-bottom: .21rem;

                #chart {
                    flex: auto;
                    // border: 1px solid red;
                    box-sizing: border-box;
                }

                .listContainer {
                    // margin-top: .44rem;
                    flex: auto;
                    overflow-y: scroll;

                    // border: 1px solid red;
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: space-between;
                    // 滚动条样式
                    &::-webkit-scrollbar-track-piece {
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar {
                        width: 8px;
                        height: 8px;
                        background-color: transparent;
                    }

                    &::-webkit-scrollbar-thumb {
                        // border-radius:0 0 5px 5px;
                        border-radius: 5px;
                        background-color: hsla(220, 4%, 58%, 0.1);
                    }

                    .item {
                        display: flex;
                        align-items: center;
                        font-size: .16rem;
                        font-weight: 400;
                        margin-top: .23rem;

                        .t {
                            color: #FFFFFF;
                            flex-shrink: 0;
                        }

                        .block {
                            margin-left: .08rem;
                            flex: auto;
                            height: .2rem;
                            position: relative;
                            background: rgba(30, 143, 255, 0.1);
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .act {
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 100%;
                                width: 10%;
                                border-radius: 0px 5px 5px 0px;
                                background-color: rgba(30, 143, 255, 0.8);
                            }

                            .v {
                                color: #FFFFFF;
                                position: relative;
                            }
                        }

                    }
                }
            }

            .card2 {
                height: 4.13rem;
                flex-shrink: 0;
                background-color: rgba(29, 201, 183, 0.03);
            }


        }
    }
</style>